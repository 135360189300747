import gsap from "gsap";
import { Draggable } from "gsap/Draggable";
import { useLayoutEffect, useRef, useState, useEffect } from "react";
import { updateJoystickAngle } from "../../../utils/helpers.utils";
import { CoordinatesIcon } from "../../../icons/Coordinates.icon";
import OrientationInitIcon from "../../../icons/OrientationInit.icon";
import OrientationLineIcon from "../../../icons/OrientationLine.icon";
import JoystickIcon from "../../../icons/Joystick.icon";

gsap.registerPlugin(Draggable);

const cardinalDirections = ["north", "east", "south", "west"];

const VideoRotary3D = ({
  video,
  updateAngleX,
  updateAngleY,
  updateFinalX,
  updateFinalY,
  initialAngleX = 0,
  initialAngleY = 0,
}) => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const joystick = useRef();
  const barre = useRef();
  const barre1 = useRef(null);
  const barre2 = useRef(null);
  const [angleX, setAngleX] = useState(0);
  const [angleY, setAngleY] = useState(0);
  const [coordinates, setCoordinates] = useState(null);
  const coordinatesRef = useRef(null);

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/

  useLayoutEffect(() => {
    let intervalX, intervalY;

    const ctx = gsap.context(() => {
      if (joystick.current) {
        let currentXAngle = initialAngleX;
        let currentYAngle = initialAngleY;

        if (barre1.current && barre2.current) {
          console.log(barre1.current);
          console.log(barre2.current);
          let percent = ((currentXAngle % 360) / 360) * 100;
          console.log("initial percent is :", percent);
          gsap.set(barre1.current, {
            xPercent: percent > -75 ? percent : percent + 200,
          });
          gsap.set(barre2.current, {
            xPercent: percent < 25 ? percent : percent - 200,
          });
        }

        gsap.set(".joystick__inner", {
          transformOrigin: "center center",
        });
        Draggable.create(".joystick__inner", {
          lockAxis: true,
          onDrag: function () {
            const direction = this.getDirection("start");
            const x = this.x;
            const y = this.y;
            clearInterval(intervalX);
            clearInterval(intervalY);
            if (direction === "left" || direction === "right") {
              intervalX = setInterval(() => {
                currentXAngle = updateJoystickAngle(x, currentXAngle);
                setAngleX(currentXAngle);
                /* update position barre */
                if (barre.current) {
                  let percent = ((currentXAngle % 360) / 360) * 100;
                  gsap.set(".orientation__barre--0", {
                    xPercent: percent > -75 ? percent : percent + 200,
                  });
                  gsap.set(".orientation__barre--1", {
                    xPercent: percent < 25 ? percent : percent - 200,
                  });
                }
              }, 500 / Math.abs(x));
            }
            if (direction === "up" || direction === "down") {
              intervalY = setInterval(() => {
                currentYAngle = updateJoystickAngle(y, currentYAngle);
                setAngleY(Math.min(Math.max(currentYAngle, -90), 90));
              }, 500 / Math.abs(y));
            }
          },
          onDragEnd: function () {
            gsap.to(".joystick__inner", {
              x: 0,
              y: 0,
              duration: 1,
              ease: "elastic",
            });
            clearInterval(intervalX);
            clearInterval(intervalY);
            updateFinalX(currentXAngle);
          },
          bounds: ".videoRotary__limit",
        });
      }
    }, joystick);
    return () => {
      ctx.revert();
      if (intervalX) clearInterval(intervalX);
      if (intervalY) clearInterval(intervalY);
    };
    // eslint-disable-next-line
  }, [joystick, barre, barre1, barre2]);

  useEffect(() => {
    updateAngleX(angleX);
    if (coordinatesRef?.current)
      gsap.set(coordinatesRef.current, {
        rotation: angleX,
        transformOrigin: "center",
      });
    // eslint-disable-next-line
  }, [angleX, coordinatesRef]);

  useEffect(() => {
    updateAngleY(angleY);
    // eslint-disable-next-line
  }, [angleY]);

  useEffect(() => {
    if (
      video.rotaryNorth &&
      video.rotaryEast &&
      video.rotarySouth &&
      video.rotaryWest
    )
      setCoordinates({
        north: video.rotaryNorth,
        east: video.rotaryEast,
        south: video.rotarySouth,
        west: video.rotaryWest,
      });
  }, [video]);

  return (
    <div ref={joystick} className="joystick">
      {coordinates && (
        <>
          {/* Orientation barre */}
          <div ref={barre} className="joystick__orientation">
            <CoordinatesIcon className="joystick__arrow joystick__arrow--top | fill-primary" />
            <CoordinatesIcon className="joystick__arrow joystick__arrow--bottom | fill-primary" />

            {Array.from({ length: 2 }).map((_, i) => (
              <div
                key={`barre--${i}`}
                ref={i === 0 ? barre1 : barre2}
                className={`orientation__barre orientation__barre--${i}`}
              >
                {cardinalDirections.map((cardinalDirection) => (
                  <div
                    key={`coordinate--${cardinalDirection}`}
                    className="barre__coordinate"
                  >
                    <div className="coordinate__name">
                      {coordinates[cardinalDirection]}
                    </div>
                    <OrientationInitIcon className="coordinate__init | fill-primary" />
                    <div className="coordinate__lines">
                      {Array.from({ length: 9 }).map((_, j) => (
                        <OrientationLineIcon
                          key={`line--${j}`}
                          className="coordinate__line | fill-primary"
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
      <div className="flex justify-center">
        <div className="relative">
          <div className="videoRotary__limit">
            <div className="videoRotary__joystick | relative flex justify-center items-center rounded-full">
              <JoystickIcon className="joystick__bg | opacity-20 fill-primary absolute w-full h-full" />
              <hr className="joystick__base | rounded-full bg-primary absolute left-1/2 -translate-x-1/2" />
              <hr className="joystick__inner | rounded-full bg-primary" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoRotary3D;
